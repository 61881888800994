import { MigeranPageViewer } from '@migeran/editor';
import { DataManager } from '@migeran/editor';
import React from 'react';
import { Typography } from '@mui/material';

export default function MigeranPage(props: any) {

  return (
    <>
    {!props.error? (
      <>
      {props.data.page_data? (
        <MigeranPageViewer data={props.data} maxWidth={props.data.page_data.editor_max_width} useStorageUrl={true}/>
      ) : (
        <Typography>Not found data</Typography>
      )}
      </>
    ) : (
      <Typography>{props.error}</Typography>
    )}
    </>
  );
}

export async function getStaticProps(context: any) {
  let id: string = context.params.id;
  if (!id) {
    id = 'index';
  }

  console.log('Page ID: ' + id);

  let isBlog = false;

  if (id == 'blog') {
    isBlog = true;
  }

  console.log('Is blog: ' + isBlog);

  const frappeServer = process.env.FRAPPE_SERVER;
  const domainName = process.env.DOMAIN_NAME;
  const frontendCode = process.env.FRONTEND_CODE;
  const cloudflare_path = process.env.CLOUDFLARE_PATH;

  let dataManager = new DataManager(cloudflare_path, frappeServer, domainName, frontendCode);
  let configurationError = dataManager.getConfigurationError();

  if (configurationError !== "") {
    return {
      props: {
        error: configurationError
      }
    }
  }

  if (isBlog) {
    return await dataManager.getBlogListData();
  } else {
    return await dataManager.getPageData(id);
  }

}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  }
}
